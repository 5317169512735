// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as ProjectWizardGeneralInformation from "../_components/ProjectWizardGeneralInformation/ProjectWizardGeneralInformation.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Configuration = {
  decoder: decoder,
  fromJson: fromJson
};

function title(cloud) {
  if (cloud === "IaasServer") {
    return "IaaS Cloud Servers Project";
  } else {
    return "IaaS Cloud Storage Project";
  }
}

function description(configurations) {
  return "The proposed cloud project has a projected service start date of " + (Belt_Array.map(configurations, (function (config) {
                    return $$Date.Naive.format(config.startDate, "dd MMM yyyy");
                  })).join(", ") + (" with a contract term of " + (Belt_Array.map(configurations, (function (config) {
                        return String(config.contractLength / 12 | 0);
                      })).join(", ") + " years.")));
}

var ProjectInfo = {
  title: title,
  description: description
};

function CreateCloudProjectGeneralInformation(props) {
  var description$1 = props.description;
  var configurations = props.configurations;
  var title$1 = props.title;
  var category = props.category;
  var projectId = props.projectId;
  var cloudType = React.useMemo((function () {
          switch (category.TAG) {
            case "Colocation" :
                return PervasivesU.failwith("Colocation category in Cloud project bundle");
            case "Cloud" :
                return category._0;
            case "Internet" :
                return PervasivesU.failwith("Internet category in Cloud project bundle");
            case "Network" :
                return PervasivesU.failwith("Network category in Cloud project bundle");
            case "BareMetal" :
                return PervasivesU.failwith("Bare Metal category in Cloud project bundle");
            
          }
        }), [category]);
  var api = React.useMemo((function () {
          var createProject = function (extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9, extra$10) {
            return Api.createNewProject({
                        TAG: "Cloud",
                        _0: cloudType
                      }, extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9, extra$10);
          };
          var saveDraftProjectAndContinueLater = function (extra) {
            return Api.saveCloudDraftProjectAndContinueLater("Cloud", {
                        TAG: "Cloud",
                        _0: cloudType
                      }, extra);
          };
          return {
                  saveDraft: Api.saveProjectGeneralInfoDraft,
                  createProject: createProject,
                  updateProject: Api.updateProject,
                  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
                };
        }), [cloudType]);
  var title$2 = React.useMemo((function () {
          if (title$1 !== undefined && title$1 !== "") {
            return title$1;
          } else {
            return title(cloudType);
          }
        }), [
        title$1,
        category
      ]);
  var description$2 = React.useMemo((function () {
          if (description$1 !== undefined && description$1 !== "") {
            return description$1;
          } else {
            return description(configurations);
          }
        }), [
        description$1,
        configurations
      ]);
  var navigationTotalSteps = React.useMemo((function () {
          return 3;
        }), [cloudType]);
  var navigationBackButtonLocation = React.useMemo((function () {
          if (projectId !== undefined) {
            return Routes_Project.Cloud.Provider.editSelector(Caml_option.valFromOption(projectId), cloudType);
          } else {
            return Routes_Project.Cloud.Provider.newSelector(cloudType);
          }
        }), [projectId]);
  return JsxRuntime.jsx(ProjectWizardGeneralInformation.make, {
              api: api,
              userLoginStatus: props.userLoginStatus,
              userRole: props.userRole,
              projectId: projectId,
              title: title$2,
              description: description$2,
              alreadyUploadedDocuments: props.alreadyUploadedDocuments,
              businessName: props.businessName,
              businessContactName: props.businessContactName,
              businessEmail: props.businessEmail,
              businessPhone: props.businessPhone,
              headquartersAddress: props.headquartersAddress,
              contacts: props.contacts,
              isAgent: props.isAgent,
              agency: props.agency,
              agencyOptions: props.agencyOptions,
              canAgencyBeManaged: props.canAgencyBeManaged,
              navigationTotalSteps: navigationTotalSteps,
              navigationBackButtonLocation: navigationBackButtonLocation,
              mobile: props.mobile,
              status: props.status
            });
}

var make = CreateCloudProjectGeneralInformation;

export {
  Configuration ,
  ProjectInfo ,
  make ,
}
/* decoder Not a pure module */
